<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Benutzer bearbeiten">
      <user-form
        :loading="loading"
        :user="user"
        :form-submitted="saveUser"
        mode="edit"
      ></user-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import UserForm from "@/components/forms/UserForm"
import UsersRepository from "@/repositories/users_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Benutzer bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    UserForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      user: {
        region_roles: {}
      }
    }
  },
  async created() {
    try {
      this.user = await UsersRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveUser(user) {
      await UsersRepository.update(user.id, user)
      this.$router.push({ name: "UsersPage" })
    }
  }
}
</script>
